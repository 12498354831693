body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.app-container {
    .table-header {
        // display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .table_wrap {
        overflow-x: auto;
    }
    .pagination {
        justify-content: center;
    }
}
